<template>
  <v-container>
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <div v-if="aircraft">
          <div class="primary--text" v-if="aircraftPart">
            Modifier pièce pour
            <span style="font-weight: bold; color: red">
              {{ aircraft.immatriculation }}
            </span>
          </div>
          <div v-else class="primary--text">
            Nouvel pièce pour
            <span style="font-weight: bold; color: red">{{
              aircraft.immatriculation
            }}</span>
          </div>
        </div>
      </v-card-title>
      <v-divider style="padding-bottom: 30px"></v-divider>
      <v-form>
        <v-row>
          <v-col cols="6" class="pt-4 pl-6">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  dense
                  outlined
                  v-model="type"
                  prepend-icon="mdi-airplane-cog"
                  label="Quel type de pièce voulez-vous ajouter ?"
                  :items="typeItems"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="type == 'Equipment' || type == 'Part'">
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="attach"
                  dense
                  outlined
                  label="A quelle entité la pièce est rattachée ?"
                  prepend-icon="mdi-airplane"
                  :items="attachItems"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-autocomplete
                  :items="
                    partModels
                      .map(function (t) {
                        if (t.tcHolder) {
                          text =
                            t.tcHolder.name.substring(0, 20) +
                            (t.designation ? ' - ' + t.designation : '') +
                            (t.reference ? ' - ' + t.reference : '');
                        } else {
                          text =
                            t.designation +
                            (t.reference ? ' - ' + t.reference : '');
                        }
                        return {
                          text: text,
                          value: t.id,
                        };
                      })
                      .sort((a, b) => a.text.localeCompare(b.text))
                  "
                  attach
                  small-chips
                  label="Indiquer le Modèle"
                  dense
                  prepend-icon="mdi-alphabetical"
                  v-model="partModelId"
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  dense
                  outlined
                  v-model="serialNumber"
                  counter="250"
                  label="Numéro de série"
                  prepend-icon="mdi-numeric"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="manufacturingDatePicker"
                      label="Date de fabrication"
                      prepend-icon="mdi-calendar-month"
                      outlined
                      dense
                      readonly
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="manufacturingDate"
                    :first-day-of-week="1"
                    locale="fr"
                    color="green"
                    no-title
                    @input="menuDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-select
                  dense
                  outlined
                  label="Choississez une position ?"
                  prepend-icon="mdi-car-seat"
                  v-model="position"
                  :items="positionItems"
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  dense
                  outlined
                  label="Quel est le constructeur ?"
                  v-model="manufacturer"
                  clearable
                  prepend-icon="mdi-home-account" 
                  v-if="partModel && partModel.tcHolder"
                  :items="
                    partModel
                      .tcHolder.manufacturers.map(function (m) {
                        return { text: m.name, value: m.id };
                      }).sort((a, b) => a.text.localeCompare(b.text))
                  "
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0 pr-0">
                <v-text-field
                  label="HH"
                  requis
                  dense
                  outlined
                  prepend-icon="mdi-airplane-clock"
                  v-model="initHours"
                >
                </v-text-field>
              </v-col>
              <span class="pa-2">:</span>
              <v-col cols="3" class="py-0 pl-0">
                <v-text-field
                  label="mm"
                  requis
                  dense
                  outlined
                  v-model="initMinutes"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  dense
                  outlined
                  v-model="initCycle"
                  label="Cycle initiale"
                  prepend-icon="mdi-airplane-check"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0 ml-8">
                <v-radio-group dense v-model="independantHour" row class="mt-0">
                  <div class="mr-8">
                    Les heures/cycles sont-elles indépendantes ?
                  </div>
                  <v-radio color="#1871ce" label="Oui" :value="true"> </v-radio>
                  <v-radio color="#1871ce" label="Non" :value="false"> </v-radio
                ></v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="partModel">
              <v-col cols="12" class="py-0">
                <v-select
                  prepend-icon="mdi-bookshelf"
                  outlined
                  dense
                  label="Selectionnez le(s) manuel(s)"
                  :multiple="true"
                  v-model="manuals"
                  :items="
                    partModel.manuals
                      .map(function (t) {
                        return {
                          text: t.number + ' - ' + t.title,
                          value: t.id,
                        };
                      })
                      .sort((a, b) => a.text.localeCompare(b.text))
                  "
                  no-data-text="Aucun manuel disponible"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="notes"
                  dense
                  outlined
                  rows="3"
                  label="Notes"
                  single-line
                  auto-grow
                  prepend-icon="mdi-file-edit-outline"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-col cols="12" class="mb-6" v-if="aircraftPart">
              <h2>
                DOCUMENTS<ajout-doc :aircraftPart="aircraftPart"></ajout-doc>
              </h2>
              <v-list>
                <v-list-item
                  v-for="document in aircraftPart.aircraftPartDocuments"
                  :key="document.id"
                >
                  <template v-if="document.documentAttachment">
                    <v-btn
                      color="red"
                      dark
                      outlined
                      small
                      target="_blank"
                      :href="document.documentAttachment.fileUrl"
                      >{{ document.name }}
                      <v-icon right dark
                        >mdi-file-pdf-box-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <ajout-doc
                    :aircraftPart="aircraftPart"
                    :aircraftPartDocument="document"
                  ></ajout-doc>
                </v-list-item>
              </v-list>
            </v-col>
          </v-col>
        </v-row>
        <v-col cols="12" align="center" class="pt-8">
          <v-btn
            class="mr-4 btn--text"
            outlined
            depressed
            v-if="aircraft"
            :to="{
              name: 'aeronef',
              params: {
                immatriculation: aircraft.immatriculation,
                page: 'pieces'
              },
            }"
            >Annuler</v-btn
          >
          <v-btn
            class="mr-4 primary primary-text"
            dark
            v-if="aircraftPart"
            @click="create()"
            >Mettre à jour</v-btn
          >
          <v-btn class="mr-4 primary primary-text" dark v-else @click="create()"
            >Créer</v-btn
          >
        </v-col>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { aircraftQuery } from "@/graphql/aircraft/aircraft";
import { partModelsQuery } from "@/graphql/part_model/part_models";
import { partModelQuery } from "@/graphql/part_model/part_model";
import { createAircraftPartMutation } from "@/graphql/aircraft_part/create_aircraft_part";
import { updateAircraftPartMutation } from "@/graphql/aircraft_part/update_aircraft_part";
import { aircraftPartsQuery } from "@/graphql/aircraft_part/aircraft_parts";
import { aircraftPartQuery } from "@/graphql/aircraft_part/aircraft_part";
import AjoutDoc from "./AjoutDoc.vue";

export default {
  components: {
    AjoutDoc,
  },
  data() {
    return {
      type: null,
      partModelId: null,
      partModel: null,
      partModels: [],
      manuals: [],
      serialNumber: null,
      manufacturingDate: null,
      manufacturer: null,
      menuDate: false,
      initMinutes: null,
      initHours: null,
      initCycle: null,
      independantHour: false,
      attach: null,
      notes: null,
      aircraftParts: [],
      uploadedFileIDs: [],
      typeItems: [
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
        { text: "Equipement (avec TcHolder)", value: "Equipment" },
        { text: "Pièce (sans TcHolder)", value: "Part" },
      ],
      position: null,
      positionItems: [
        { text: "", value: null },
        { text: "Avant", value: "Avant" },
        { text: "Milieu", value: "Milieu" },
        { text: "Arrière", value: "Arrière" },
        { text: "droite", value: "droite" },
        { text: "gauche", value: "gauche" },
        { text: "pilote", value: "pilote" },
        { text: "co-pilote", value: "co-pilote" },
        { text: "passager", value: "passager" },
      ],
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  methods: {
    create() {
      if (this.aircraftPart) {
        this.$apollo
          .mutate({
            mutation: updateAircraftPartMutation,
            variables: {
              input: {
                id: parseInt(this.aircraftPart.id, 10),
                attributes: {
                  serialNumber: this.serialNumber,
                  partModelId: this.partModelId,
                  manufacturingDate: this.manufacturingDate,
                  flyValue: this.calcFlyValue,
                  independantHour: this.independantHour,
                  manufacturerId: this.manufacturer,
                  attachId: this.attach,
                  notes: this.notes,
                  formOne: this.uploadedFileIDs,
                  position: this.position,
                  manualsData: this.manuals.map((ma) => parseInt(ma, 10)),
                },
              },
            },
          })
          .then(() => {
            this.$router.push({
              name: "aeronef",
              params: {
                immatriculation: this.aircraft.immatriculation,
                page: "pieces",
              },
            });
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftPartMutation,
            variables: {
              input: {
                attributes: {
                  serialNumber: this.serialNumber,
                  aircraftId: this.aircraft.id,
                  partModelId: this.partModelId,
                  manufacturingDate: this.manufacturingDate,
                  manufacturerId: this.manufacturer,
                  flyValue: this.calcFlyValue,
                  independantHour: this.independantHour,
                  attachId: this.attach,
                  notes: this.notes,
                  formOne: this.uploadedFileIDs,
                  position: this.position,
                  manualsData: this.manuals.map((ma) => parseInt(ma, 10)),
                },
              },
            },
          })
          .then(() => {
            this.$router.push({
              name: "aeronef",
              params: {
                immatriculation: this.aircraft.immatriculation,
                page: "pieces",
              },
            });
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    totalHours(hours, minutes) {
      if (!hours && !minutes) {
        return null;
      }
      var totalMinutes = 0;
      if (hours) {
        totalMinutes += parseInt(hours, 10) * 60;
      }
      if (minutes) {
        totalMinutes += parseInt(minutes, 10);
      }
      return totalMinutes;
    },
    setHoursAndMinutes(total) {
      const minutes = total % 60;
      const hours = Math.floor(total / 60);
      return {
        hours: hours,
        minutes: minutes,
      };
    },
    setFlyValue(flyValue) {
      let cycle = flyValue["init"].find((v) => v['unit_code'] == "cycle")
      if (cycle) {
        this.initCycle = cycle["value"];
      }
      let hours = flyValue["init"].find((v) => v['unit_code'] == "hours")
      if (hours) {
        console.log(hours)
        const h = this.setHoursAndMinutes(hours["value"])
        this.initMinutes = h["minutes"]
        this.initHours = h["hours"]
      }
    }
  },
  computed: {
    manufacturingDatePicker() {
      return this.formatDate(this.manufacturingDate);
    },
    calcFlyValue() {
      var h = { init: [], current: [] };
      h["init"].push({
        value: this.totalHours(this.initHours, this.initMinutes),
        unit_code: "hours",
      });
      h["init"].push({
        value: parseInt(this.initCycle, 10),
        unit_code: "cycles",
      });
      return h;
    },
    attachItems() {
      var items = [{ text: "Sans Attache", value: null }];
      if (this.aircraftParts) {
        this.aircraftParts.map(function (ap) {
          var text = ap.partModel.category.name;
          if (ap.position) {
            text = text + " (" + ap.position + ")";
          }
          text =
            text +
            " - " +
            ap.partModel.reference +
            " (s/n: " +
            ap.serialNumber +
            ")";
          items.push({
            text: text,
            value: ap.id,
          });
        });
      }
      return items;
    },
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
    },
    aircraftPart: {
      query: aircraftPartQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      result({ data }) {
        if (data) {
          this.type = data.aircraftPart.partModel.typeRaw;
          this.serialNumber = data.aircraftPart.serialNumber;
          this.partModelId = data.aircraftPart.partModel.id.toString();
          this.manuals = data.aircraftPart.manualsData.map((ma) =>
            ma.toString()
          );          
          this.independantHour = data.aircraftPart.independantHour;
          this.manufacturingDate = data.aircraftPart.manufacturingDate;
          if (data.aircraftPart.attach) {
            this.attach = data.aircraftPart.attach.id;
          }
          if (data.aircraftPart.manufacturer) {
            this.manufacturer = data.aircraftPart.manufacturer.id;
          }
          this.notes = data.aircraftPart.notes;
          this.position = data.aircraftPart.position;
          this.uploadedFileIDs = data.aircraftPart.formOne.map(
            (fo) => fo.signedId
          );
          this.setFlyValue(data.aircraftPart.flyValue)          
        }
      },
    },
    partModels: {
      query: partModelsQuery,
      variables() {
        return { tcHolderId: null, type: this.type, serie: false };
      },
      skip() {
        return !this.type;
      },
    },
    partModel: {
      query: partModelQuery,
      variables() {
        return { id: parseInt(this.partModelId, 10) };
      },
      skip() {
        return !this.partModelId;
      },
    },
    aircraftParts: {
      query: aircraftPartsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          type: ["Cell", "Motor", "Propeller"],
        };
      },
      skip() {
        return !this.type || !this.aircraft;
      },
    },
  },
};
</script>