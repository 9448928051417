<template>
  <v-container fluid class="background">
    <v-col cols="10" offset="1">
      <v-sheet outlined >
        <v-card-title>
          <div>
            <div class="primary--text">
              Template du
              <!-- <span style="font-weight: bold; color: red"> {{ templateAircraftParts.templateAircrafts.name }} </span> -->
            </div>
          </div>
          <v-spacer></v-spacer>
          <template-part-form 
						
					></template-part-form>
        </v-card-title>
        <v-data-table
          :items="templateAircraftParts"
          :headers="header"
          :items-per-page="-1"
          hide-default-footer
          :search="search"
          sort-by="partModel.ata.chapter"
          dense
          show-expand
          no-results-text="Aucun résultat"
          :expanded.sync="expanded"
        >
          <template v-slot:item.partModel.designation="{ item }">
            <div v-if="item.position">
              {{ item.partModel.designation }}
              <span class="font-italic">({{ item.position }})</span>
            </div>
            <span v-else> {{ item.partModel.designation }}</span>
          </template>
          <template v-slot:expanded-item="{headers, item}">
            <td :colspan="headers.length">
               <template-item-stop :templateAircraftPart="item"></template-item-stop>
            </td>
          </template>
          <template v-slot:item.action="{ item }">
            <template-Part-Form :templateAircraftPart="item" ></template-Part-Form>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-container>
</template>


<style scoped>

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none; 
}
</style>

<script>
import { templateAircraftPartsQuery } from "../../../graphql/template/template_aircraft_parts";
import TemplateItemStop from "./TemplateItemStop.vue";
import templatePartForm from "./TemplatePartForm.vue";

export default {
  components: {
    TemplateItemStop,
    templatePartForm,
  },
  data() {
    return {
      header: [
        { text: "ATA", value: "partModel.ata.chapter", width: "70px" },
        { text: "CATEGORIE", value: "partModel.category.name" },
        { text: "TCHOLDER", value: "partModel.tcHolder.name" },
        { text: "DESIGNATION", value: "partModel.designation" },
        { text: "P/N", value: "partModel.reference" },
				{ text: "Action", value: "action", sortable: false },	
        { text: "", value: "data-table-expand"}      ],
      search: "",
      isLoading: true,
      expanded: [],
      all: false,

    };
  },
  apollo: {
    templateAircraftParts: {
      query: templateAircraftPartsQuery,
      variables() {
        return {
          templateAircraftId: this.$route.params.id,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>