<template>
  <v-container fluid class="background">
    <v-col cols="6" offset="3">
      <v-sheet outlined >
        <v-card-title>
          <v-btn class="btn" :to="{ name: 'template_new' }"
            ><v-icon left dark> mdi-plus </v-icon>Nouveau template</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :items="templateAircrafts"
          :headers="header"
          :footer-props="{
            'items-per-page-options': [13, 50, 100, -1],
            'items-per-page-text': 'lignes par page',
            'items-per-page-all-text': 'Tous',
          }"
          :items-per-page="13"
          :search="search"
          dense
          no-results-text="Aucun résultat"
        >
 
          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon :to="{ name: 'template', params: { id: item.id } }">
                  <v-icon v-on="on">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }" v-slot:item.id="{ item }">
                <v-btn icon @click="deleteAta(item)">
                  <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </template>
        </v-data-table>
      <div v-if="$apollo.loading" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue-grey"
          indeterminate
        ></v-progress-circular>
      </div>
        <confirm ref="confirm"></confirm>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<style scoped>
</style>

<script>
import { templateAircraftsQuery } from "../../../graphql/template/template_aircrafts";
import confirm from "@/components/confirm.vue";

export default {
	name : "dataTableTemplate",
  components: {
    confirm,
  },
  data() {
    return {
			templateAircrafts: [],
      header: [
        { text: "MODELE", value: "name" },
        { text: " ", value: "action", width: "100px", filterable: false },
      ],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    templateAircrafts: {
      query: templateAircraftsQuery,
    },
  },
  // methods: {
  //   deleteAta(ata) {
  //     this.$refs.confirm
  //       .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
  //       .then((confirm) => {
  //         if (confirm) {
  //           console.log(ata);
  //           this.$apollo
  //             .mutate({
  //               mutation: deleteAtaMutation,
  //               variables: {
  //                 input: {
  //                   id: parseInt(ata.id, 10),
  //                 },
  //               },
  //               update: (store, { data: { deleteAta } }) => {
  //                 const data = store.readQuery({ query: templatesQuery });
  //                 const newData = {
  //                   atas: data.atas.filter((b) => b.id !== deleteAta.ata.id),
  //                 };
  //                 store.writeQuery({ query: templatesQuery, data: newData });
  //               },
  //             })
  //             .then((data) => {
  //               console.log(data);
  //             });
  //         }
  //       });
  //   },
  // },
};
</script>