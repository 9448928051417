import gql from 'graphql-tag';

export const templateAircraftPartsQuery = gql`
query templateAircraftParts($templateAircraftId: ID!) {
  templateAircraftParts(templateAircraftId: $templateAircraftId) {
    id
    partModel {
      id
      reference
      designation
      type
      typeRaw
      tcHolder {
        id
        name
      }
      ata {
        id
        chapter
      }
      category {
        id
        name
      }
    }
    position
    templateItemStops {
      action {
      id
      name  
      }
      additionalInfoFr
      stop {
        id
        name
        inspection
      }
    }
  }
}`