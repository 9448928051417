<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="templateAircraftPart" icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-else dark outlined color="primary" v-bind="attrs" v-on="on"
        >ajouter une Pièce
      </v-btn>
    </template>
    <v-card >
      <v-card-title class="headline text-uppercase">
        <div>
        <span class="primary--text" v-if="templatePart">MODIFIER une Pièce</span>
        <span class="primary--text" v-else>AJOUTER une Pièce</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="templatePart"
          ><v-icon color="red">mdi-delete</v-icon></v-btn
        >
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-form class="mt-6">
        <v-col>
          <v-row>
            <v-col cols="6" offset="3" class="pt-4 pl-6">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-select
                    dense
                    outlined
                    v-model="type"
                    prepend-icon="mdi-airplane-cog"
                    label="Quel type de pièce voulez-vous ajouter ?"
                    :items="typeItems"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="type == 'Equipment' || type == 'Part'">
                <v-col cols="12" class="py-0">
                  <v-select
                    v-model="attach"
                    dense
                    outlined
                    label="A quelle entité la pièce est rattachée ?"
                    prepend-icon="mdi-airplane"
                    :items="attachItems"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    
                    attach
                    small-chips
                    label="Indiquer le Modèle"
                    dense
                    prepend-icon="mdi-alphabetical"
                    v-model="partModelId"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-select
                    dense
                    outlined
                    label="Choississez une position ?"
                    prepend-icon="mdi-car-seat"
                    v-model="position"
                    :items="positionItems"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="pt-2 pb-6">
              <v-btn
                class="mr-4 btn--text"
                outlined
                depressed
                @click="closeDialog()"
                >Annuler</v-btn
              >
              <v-btn
                class="mr-4 primary primary-text"
                dark
                @click="create()"
                >Mettre à jour</v-btn
              >
              <!-- <v-btn class="mr-4 primary primary-text" dark v-else @click="create()"
                >Créer</v-btn
              > -->
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
// import { createTemplateMutation } from "@/graphql/template/create_template";
// import { templateQuery } from "@/graphql/template/templates";

export default {
  name: "NewTemplate",
  props: {
		templateAircraftPart: {
			type: Object,
			required: false,
		},
	},
  components: {

  },
  data() {
    return {
			type: null,
			typeItems: [
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
        { text: "Equipement (avec TcHolder)", value: "Equipment" },
        { text: "Pièce (sans TcHolder)", value: "Part" },
      ],
      position: null,
      positionItems: [
        { text: "", value: null },
        { text: "Avant", value: "Avant" },
        { text: "Milieu", value: "Milieu" },
        { text: "Arrière", value: "Arrière" },
        { text: "droite", value: "droite" },
        { text: "gauche", value: "gauche" },
        { text: "pilote", value: "pilote" },
        { text: "co-pilote", value: "co-pilote" },
        { text: "passager", value: "passager" },
      ],
      dialog: false,
      

    };
  },
  // apollo: {
  //   templates: {
  //     query: templateQuery,
  //   },
  // },
//  methods: {
//    create() {
// 		if (this.aircraftPart) {
// 		this.$apollo
// 			.mutate({
// 				mutation: updateTemplatePartMutation,
// 				variables: {
// 					input: {
// 						id: parseInt(this.aircraftPart.id, 10),
// 						attributes: {
// 							partModelId: this.partModelId,
// 							position: this.position,
// 						},
// 					},
// 				},
// 			})
// 			.then(() => {
// 				this.$router.push({
// 					name: "aeronef",
// 					params: {
// 						immatriculation: this.aircraft.immatriculation,
// 						page: "pieces",
// 					},
// 				});
// 			});
//       } else {
//         this.$apollo
//           .mutate({
//             mutation: createTemplateMutation,
//             variables: {
//               input: {
//                 attributes: {
//                   aircraftId: this.aircraft.id,
//                   partModelId: this.partModelId,
//                   position: this.position,
//                 },
//               },
//             },
//           })
//           .then(() => {
//             this.$router.push({
//               name: "aeronef",
//               params: {
//                 immatriculation: this.aircraft.immatriculation,
//                 page: "pieces",
//               },
//             });
//           });
//     	}
//  		},
// 	},
};
</script>